// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-flex {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.loading-flex > div {
    width: 50%;
    margin: auto;
    box-sizing: border-box;
    position: relative;
    font-size: 16px;
}

.loading-flex > div {
    padding-right: 30px;
    margin-top: 20px;
}


.loading-container {
    width: 100%;
    height: 5px;
    background-color: #009FD6;
    position: relative;
    margin-top: 7px;
}

.loading-bar {
    width: 0;
    height: 100%;
    background-color: #eed15e;
    transition: width 0.5s;
}

.skill-per {
    position: absolute;
    right: 30px;
}


.skillservice {
    margin-top: 70px;
    margin-bottom: 30px;
}

.skillservice > h1 {
    font-size: 25px;
    margin-bottom: 10px;
}

.skillservice > p {
    font-size: 16px;
    margin-top: 50px;
}

.service-p {
    font-size: 13px;
    word-spacing: 0.3em; /* Adjust the value to increase or decrease word spacing */
    line-height: .8; 
    margin-bottom: 70px;
}

.serviceFullStop {
    padding-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/styles/rightRail/skillservice.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,WAAW;AACf;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;;AAGA;IACI,WAAW;IACX,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,QAAQ;IACR,YAAY;IACZ,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf;;;AAGA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,mBAAmB,EAAE,0DAA0D;IAC/E,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".loading-flex {\n    display: flex;\n    flex-wrap: wrap;\n    width: 100%;\n}\n\n.loading-flex > div {\n    width: 50%;\n    margin: auto;\n    box-sizing: border-box;\n    position: relative;\n    font-size: 16px;\n}\n\n.loading-flex > div {\n    padding-right: 30px;\n    margin-top: 20px;\n}\n\n\n.loading-container {\n    width: 100%;\n    height: 5px;\n    background-color: #009FD6;\n    position: relative;\n    margin-top: 7px;\n}\n\n.loading-bar {\n    width: 0;\n    height: 100%;\n    background-color: #eed15e;\n    transition: width 0.5s;\n}\n\n.skill-per {\n    position: absolute;\n    right: 30px;\n}\n\n\n.skillservice {\n    margin-top: 70px;\n    margin-bottom: 30px;\n}\n\n.skillservice > h1 {\n    font-size: 25px;\n    margin-bottom: 10px;\n}\n\n.skillservice > p {\n    font-size: 16px;\n    margin-top: 50px;\n}\n\n.service-p {\n    font-size: 13px;\n    word-spacing: 0.3em; /* Adjust the value to increase or decrease word spacing */\n    line-height: .8; \n    margin-bottom: 70px;\n}\n\n.serviceFullStop {\n    padding-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
