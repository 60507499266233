// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appear {
    opacity: 0;
    transition: opacity 2.5s, transform 0.3s ease-in;
}
  
.move-up {
  transform: translateY(30px); /* Initial position offscreen */
}

.appear.appeared {
  opacity: 1;
  transform: translateY(0); /* Final position onscreen */
}`, "",{"version":3,"sources":["webpack://./src/styles/rightRail/intersection.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gDAAgD;AACpD;;AAEA;EACE,2BAA2B,EAAE,+BAA+B;AAC9D;;AAEA;EACE,UAAU;EACV,wBAAwB,EAAE,4BAA4B;AACxD","sourcesContent":[".appear {\n    opacity: 0;\n    transition: opacity 2.5s, transform 0.3s ease-in;\n}\n  \n.move-up {\n  transform: translateY(30px); /* Initial position offscreen */\n}\n\n.appear.appeared {\n  opacity: 1;\n  transform: translateY(0); /* Final position onscreen */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
