// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.finger-scroll-container {
    position: absolute;
    bottom: 40%;
    right: 50px;
    z-index: 999;
    animation: moveUpDown 2s linear infinite;
    width: 50px;
    height: 50px;
}

.finger-scroll-container > img {
  height: 50px !important;
  filter: brightness(0) saturate(100%) hue-rotate(0deg) sepia(100%) invert(100%);
  
}

/* 
.finger {
    font-size: 30px;
    cursor: pointer;
} */

.svg-inline--fa > path {
    color: #009FD6;
}

@keyframes moveUpDown {
  0% {
    bottom: 40%;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    bottom: 60%;
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/rightRail/finger.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,YAAY;IACZ,wCAAwC;IACxC,WAAW;IACX,YAAY;AAChB;;AAEA;EACE,uBAAuB;EACvB,8EAA8E;;AAEhF;;AAEA;;;;GAIG;;AAEH;IACI,cAAc;AAClB;;AAEA;EACE;IACE,WAAW;IACX,UAAU;EACZ;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;EACA;IACE,WAAW;IACX,UAAU;EACZ;AACF","sourcesContent":[".finger-scroll-container {\n    position: absolute;\n    bottom: 40%;\n    right: 50px;\n    z-index: 999;\n    animation: moveUpDown 2s linear infinite;\n    width: 50px;\n    height: 50px;\n}\n\n.finger-scroll-container > img {\n  height: 50px !important;\n  filter: brightness(0) saturate(100%) hue-rotate(0deg) sepia(100%) invert(100%);\n  \n}\n\n/* \n.finger {\n    font-size: 30px;\n    cursor: pointer;\n} */\n\n.svg-inline--fa > path {\n    color: #009FD6;\n}\n\n@keyframes moveUpDown {\n  0% {\n    bottom: 40%;\n    opacity: 0;\n  }\n  10% {\n    opacity: 1;\n  }\n  90% {\n    opacity: 0;\n  }\n  100% {\n    bottom: 60%;\n    opacity: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
