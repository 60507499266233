// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle {
    height: 120px;
    width: 120px;
    border-radius: 120px;
    background-color: #10172A;
    position: fixed; 
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 99999999; /* so that it stays on top of all other elements */
    filter: blur(80px); /* Apply blur effect */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); /* Add shadow effect */
    opacity: .4;
    transition: background-color 2s ease-in-out; /* Apply transition to background-color */
  }

  .zoom {
    color: #ad973e;;
  }

  `, "",{"version":3,"sources":["webpack://./src/styles/circle.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,oBAAoB;IACpB,yBAAyB;IACzB,eAAe;IACf,MAAM;IACN,OAAO;IACP,oBAAoB;IACpB,iBAAiB,EAAE,kDAAkD;IACrE,kBAAkB,EAAE,sBAAsB;IAC1C,uCAAuC,EAAE,sBAAsB;IAC/D,WAAW;IACX,2CAA2C,EAAE,yCAAyC;EACxF;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".circle {\n    height: 120px;\n    width: 120px;\n    border-radius: 120px;\n    background-color: #10172A;\n    position: fixed; \n    top: 0;\n    left: 0;\n    pointer-events: none;\n    z-index: 99999999; /* so that it stays on top of all other elements */\n    filter: blur(80px); /* Apply blur effect */\n    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); /* Add shadow effect */\n    opacity: .4;\n    transition: background-color 2s ease-in-out; /* Apply transition to background-color */\n  }\n\n  .zoom {\n    color: #ad973e;;\n  }\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
